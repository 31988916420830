import http from '../../../services/http';

export default class Document {
  static fetchDocumentTypes() {
    return http.get('/documenttypes');
  }

  static fetchStates() {
    return http.get('/states');
  }

  static fetchDocumentSets() {
    return http.get('/documentsets');
  }

  static uploadDocument(body, params) {
    return http.post('/documents/add', body, {
      params: {
        data: params
      }
    });
  }

  static fetchDocumentPages(documentId, userId, search) {
    return http.get(`/documents/${documentId}/get-pages`, {
      params: { userId, search }
    });
  }

  static fetchDocumentsOfProperty(propertyId, params, isAuthenticated) {
    if (isAuthenticated) {
      return http.get(`/documents/of-property/${propertyId}`, {
        params: {
          ...params,
          timeStamp: Date.now().valueOf()
        }
      });
    }
    return http.get(`/documents/of-property-public/${propertyId}`, {
      params: {
        ...params,
        timeStamp: Date.now().valueOf()
      }
    });
  }

  static saveDocumentIndex(documentId, body) {
    return http.post(`/documents/${documentId}/save-pages-index`, body);
  }

  static insertPagesToDocument(body, params) {
    return http.post('/documents/insert-pages', body, {
      params
    });
  }

  static exportDocumentFromPages(body, params) {
    return http.post('/documents/export-pages', body, {
      params
    });
  }

  static deleteDocument(params) {
    return http.delete('/documents/destroy', {
      params
    });
  }

  static editDocument(body, documentId) {
    return http.patch(`/documents/${documentId}`, body);
  }

  static editDocumentUserIndex(body, documentUserIndexId) {
    return http.patch(`/documentuserindices/${documentUserIndexId}`, body);
  }

  static mergePdfDocument(body) {
    return http.post('/documents/merge-pdf', body, {
      responseType: 'blob'
    });
  }

  static textDetection(body) {
    return http.post('/documents/text-detection', body);
  }

  static downloadDocument(documentId) {
    return http.get(`/documents/generate-pdf-with-annotation/${documentId}`, {
      responseType: 'blob'
    });
  }

  static releaseDocuments(body) {
    return http.patch('/documents/release-documents', body);
  }

  static downloadDocumentByChecked(listIds) {
    return http.post('/documents/download-document-by-checked', { listIds }, {
      responseType: 'blob'
    });
  }

  static editPageInfo(documentId, body) {
    return http.patch(`/DocumentUserIndices/${documentId}`, body);
  }

  static switchDocumentAndPage(body) {
    return http.post('/documents/switch-document-and-page', body);
  }

  static deletePages(body) {
    return http.post('/documents/delete-pages', body);
  }

  static fixedChecklist(transactionId) {
    return http.post(`/documents/trans/${transactionId}/check-fixed-checklist`);
  }

  static updateChecklist(transactionId, documentId, data) {
    return http.patch(`/documents/trans/${transactionId}/update-checklist/${documentId}`, data);
  }

  static alertAcknowledge() {
    return http.get('/documents/alert-acknowledge');
  }
}
