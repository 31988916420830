import {
  createAction,
  createActions
} from 'redux-actions';
import {
  find,
  get
} from 'lodash';
import FileSaver from 'file-saver';

import Api from './api';
import { decryptString } from '../../../utils/encryption';

export const getDocumentItems = createAction('GET_DOCUMENT_ITEMS');

/** FETCH DOCUMENT SETS **/
const { fetchDocumentSetsRequest, fetchDocumentSetsSuccess, fetchDocumentSetsFail } = createActions({
  FETCH_DOCUMENT_SETS_REQUEST: () => { },
  FETCH_DOCUMENT_SETS_SUCCESS: data => ({ data }),
  FETCH_DOCUMENT_SETS_FAIL: error => ({ error })
});

export const fetchDocumentSets = () => (dispatch) => {
  dispatch(fetchDocumentSetsRequest());

  return Api.fetchDocumentSets()
    .then(({ data }) => {
      dispatch(fetchDocumentSetsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchDocumentSetsFail(error));
    });
};


/** FETCH DOCUMENT TYPES **/
const { fetchDocumentTypesRequest, fetchDocumentTypesSuccess, fetchDocumentTypesFail } = createActions({
  FETCH_DOCUMENT_TYPES_REQUEST: () => { },
  FETCH_DOCUMENT_TYPES_SUCCESS: data => ({ data }),
  FETCH_DOCUMENT_TYPES_FAIL: error => ({ error })
});

export const fetchDocumentTypes = () => (dispatch) => {
  dispatch(fetchDocumentTypesRequest());

  return Api.fetchDocumentTypes()
    .then(({ data }) => {
      dispatch(fetchDocumentTypesSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchDocumentTypesFail(error));
    });
};


/** FETCH STATES **/
const { fetchStatesRequest, fetchStatesSuccess, fetchStatesFail } = createActions({
  FETCH_STATES_REQUEST: () => { },
  FETCH_STATES_SUCCESS: data => ({ data }),
  FETCH_STATES_FAIL: error => ({ error })
});

export const fetchStates = () => (dispatch) => {
  dispatch(fetchStatesRequest());

  return Api.fetchStates()
    .then(({ data }) => {
      dispatch(fetchStatesSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchStatesFail(error));
    });
};

/** UPLOAD DOCUMENT **/
const { uploadDocumentRequest, uploadDocumentSuccess, uploadDocumentFail } = createActions({
  UPLOAD_DOCUMENT_REQUEST: () => { },
  UPLOAD_DOCUMENT_SUCCESS: data => ({ data }),
  UPLOAD_DOCUMENT_FAIL: error => ({ error })
});

export const uploadDocument = (body, params) => (dispatch) => {
  try {
    dispatch(uploadDocumentRequest());
  } catch (e) {
  }

  return Api.uploadDocument(body, params)
    .then(({ data }) => {
      dispatch(uploadDocumentSuccess(data));
    })
    .catch((error) => {
      dispatch(uploadDocumentFail(error));
    });
};

/** FETCH DOCUMENTS OF PROPERTY **/
const { fetchDocumentsOfPropertyRequest, fetchDocumentsOfPropertySuccess, fetchDocumentsOfPropertyFail } = createActions({
  FETCH_DOCUMENTS_OF_PROPERTY_REQUEST: () => { },
  FETCH_DOCUMENTS_OF_PROPERTY_SUCCESS: (data, categories) => ({ data, categories }),
  FETCH_DOCUMENTS_OF_PROPERTY_FAIL: error => ({ error })
});

const handleDecryptDocuments = fullDocuments => {
  for (const category of fullDocuments) {
    const { documents } = category;
    for (const document of documents) {
      if (!document.link.startsWith('http')) {
        document.link = decryptString(document.link, document.id);
      }

      for (const page of document.pages) {
        const { documentIndex } = page;
        if (documentIndex && documentIndex.link && !documentIndex.link.startsWith('http')) {
          documentIndex.link = decryptString(documentIndex.link, documentIndex.id);
        }
      }
    }
  }
  return fullDocuments;
};

export const fetchDocumentsOfProperty = (propertyId, params, isAuthenticated = true) => (dispatch) => {
  dispatch(fetchDocumentsOfPropertyRequest());

  return Api.fetchDocumentsOfProperty(propertyId, params, isAuthenticated)
    .then(({ data }) => {
      const categories = get(data, 'documents', [])
        .map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
      data.documents = handleDecryptDocuments(data.documents);
      dispatch(fetchDocumentsOfPropertySuccess(data, categories));
    })
    .catch((error) => {
      dispatch(fetchDocumentsOfPropertyFail(error));
    });
};


/** FETCH PAGES DOCUMENT **/
const { fetchDocumentPagesRequest, fetchDocumentPagesSuccess, fetchDocumentPagesFail } = createActions({
  FETCH_DOCUMENT_PAGES_REQUEST: () => { },
  FETCH_DOCUMENT_PAGES_SUCCESS: data => ({ data }),
  FETCH_DOCUMENT_PAGES_FAIL: error => ({ error })
});

export const fetchDocumentPages = (documentId, userId, search) => (dispatch) => {
  dispatch(fetchDocumentPagesRequest());

  return Api.fetchDocumentPages(documentId, userId, search)
    .then(({ data }) => {
      dispatch(fetchDocumentPagesSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchDocumentPagesFail(error));
    });
};


/** SAVE DOCUMENT INDEX **/
const { saveDocumentIndexRequest, saveDocumentIndexSuccess, saveDocumentIndexFail } = createActions({
  SAVE_DOCUMENT_INDEX_REQUEST: () => { },
  SAVE_DOCUMENT_INDEX_SUCCESS: data => ({ data }),
  SAVE_DOCUMENT_INDEX_FAIL: error => ({ error })
});

export const saveDocumentIndex = (documentId, body) => (dispatch) => {
  dispatch(saveDocumentIndexRequest());

  return Api.saveDocumentIndex(documentId, body)
    .then(({ data }) => {
      dispatch(saveDocumentIndexSuccess(data));
    })
    .catch((error) => {
      dispatch(saveDocumentIndexFail(error));
    });
};


/** INSERT PAGES TO DOCUMENT **/
const { insertPagesToDocumentRequest, insertPagesToDocumentSuccess, insertPagesToDocumentFail } = createActions({
  INSERT_PAGES_TO_DOCUMENT_REQUEST: () => { },
  INSERT_PAGES_TO_DOCUMENT_SUCCESS: data => ({ data }),
  INSERT_PAGES_TO_DOCUMENT_FAIL: error => ({ error })
});

export const insertPagesToDocument = (body, params) => (dispatch) => {
  dispatch(insertPagesToDocumentRequest());

  return Api.insertPagesToDocument(body, params)
    .then(({ data }) => {
      dispatch(insertPagesToDocumentSuccess(data));
    })
    .catch((error) => {
      dispatch(insertPagesToDocumentFail(error));
    });
};

/** EXPORT DOCUMENT FROM PAGES **/
const { exportDocumentFromPagesRequest, exportDocumentFromPagesSuccess, exportDocumentFromPagesFail } = createActions({
  EXPORT_DOCUMENT_FROM_PAGES_REQUEST: () => { },
  EXPORT_DOCUMENT_FROM_PAGES_SUCCESS: data => ({ data }),
  EXPORT_DOCUMENT_FROM_PAGES_FAIL: error => ({ error })
});

export const exportDocumentFromPages = (body, params) => (dispatch) => {
  dispatch(exportDocumentFromPagesRequest());

  return Api.exportDocumentFromPages(body, params)
    .then(({ data }) => {
      dispatch(exportDocumentFromPagesSuccess(data));
    })
    .catch((error) => {
      dispatch(exportDocumentFromPagesFail(error));
    });
};

/** EDIT DOCUMENT **/
const { editDocumentRequest, editDocumentSuccess, editDocumentFail } = createActions({
  EDIT_DOCUMENT_REQUEST: () => { },
  EDIT_DOCUMENT_SUCCESS: (data, documents) => ({ data, documents }),
  EDIT_DOCUMENT_FAIL: error => ({ error })
});

export const editDocument = (body, documentId) => (dispatch, getState) => {
  dispatch(editDocumentRequest());

  return Api.editDocument(body, documentId)
    .then(({ data }) => {
      const { propertyDocuments } = getState().document;
      const { documentCategoryId, id } = data;
      const documentsData = propertyDocuments.result;
      const category = find(documentsData.documents, { id: documentCategoryId });
      if (category) {
        let document = find(category.documents, { id });
        if (document) {
          document = { ...document, ...data };
          for (let i = 0; i < category.documents.length; i++) {
            if (category.documents[i].id === id) {
              category.documents[i] = document;
              break;
            }
          }
        }
      }
      for (let i = 0; i < documentsData.documents.length; i++) {
        if (documentsData.documents[i].id === category.id) {
          documentsData.documents[i] = category;
          break;
        }
      }
      const categories = get(documentsData, 'documents', [])
        .map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
      dispatch(fetchDocumentsOfPropertySuccess(documentsData, categories));
      dispatch(editDocumentSuccess(data));
    })
    .catch((error) => {
      dispatch(editDocumentFail(error));
    });
};


/** DELETE DOCUMENT **/
const { deleteDocumentRequest, deleteDocumentSuccess, deleteDocumentFail } = createActions({
  DELETE_DOCUMENT_REQUEST: () => { },
  DELETE_DOCUMENT_SUCCESS: data => ({ data }),
  DELETE_DOCUMENT_FAIL: error => ({ error })
});

export const deleteDocument = (params) => (dispatch) => {
  dispatch(deleteDocumentRequest());

  return Api.deleteDocument(params)
    .then(({ data }) => {
      dispatch(deleteDocumentSuccess(data));
    })
    .catch((error) => {
      dispatch(deleteDocumentFail(error));
    });
};


/** EDIT_DOCUMENT_USER_INDEX **/
const { editDocumentUserIndexRequest, editDocumentUserIndexSuccess, editDocumentUserIndexFail } = createActions({
  EDIT_DOCUMENT_USER_INDEX_REQUEST: () => { },
  EDIT_DOCUMENT_USER_INDEX_SUCCESS: data => ({ data }),
  EDIT_DOCUMENT_USER_INDEX_FAIL: error => ({ error })
});

export const editDocumentUserIndex = (body, documentUserIndexId) => (dispatch) => {
  dispatch(editDocumentUserIndexRequest());

  return Api.editDocumentUserIndex(body, documentUserIndexId)
    .then(({ data }) => {
      dispatch(editDocumentUserIndexSuccess(data));
    })
    .catch((error) => {
      dispatch(editDocumentUserIndexFail(error));
    });
};

const { mergePdfDocumentRequest, mergePdfDocumentSuccess, mergePdfDocumentFail } = createActions({
  MERGE_PDF_DOCUMENT_REQUEST: () => { },
  MERGE_PDF_DOCUMENT_SUCCESS: data => ({ data }),
  MERGE_PDF_DOCUMENT_FAIL: error => ({ error })
});

export const mergePdfDocument = body => (dispatch) => {
  dispatch(mergePdfDocumentRequest());

  return new Promise((resolve => {
    Api.mergePdfDocument(body)
      .then(({ data }) => {
        FileSaver.saveAs(data, `Download-${Date.now()}.pdf`);
        dispatch(mergePdfDocumentSuccess(true));
        resolve(data);
      })
      .catch((error) => {
        dispatch(mergePdfDocumentFail(error));
        resolve();
      });
  }));
};

const { downloadDocumentRequest, downloadDocumentSuccess, downloadDocumentFail } = createActions({
  DOWNLOAD_DOCUMENT_REQUEST: () => { },
  DOWNLOAD_DOCUMENT_SUCCESS: data => ({ data }),
  DOWNLOAD_DOCUMENT_FAIL: error => ({ error })
});

export const downloadDocument = documentId => (dispatch) => {
  dispatch(downloadDocumentRequest());

  return new Promise((resolve => {
    Api.downloadDocument(documentId)
      .then(({ data }) => {
        FileSaver.saveAs(data, `Download-${Date.now()}.pdf`);
        dispatch(downloadDocumentSuccess(true));
        resolve(data);
      })
      .catch((error) => {
        dispatch(downloadDocumentFail(error));
        resolve();
      });
  }));
};

const { textDetectionRequest, textDetectionSuccess, textDetectionFail } = createActions({
  TEXT_DETECTION_REQUEST: () => { },
  TEXT_DETECTION_SUCCESS: data => ({ data }),
  TEXT_DETECTION_FAIL: error => ({ error })
});

export const textDetection = body => (dispatch) => {
  dispatch(textDetectionRequest());

  return Api.textDetection(body)
    .then(({ data }) => {
      return dispatch(textDetectionSuccess(data));
    })
    .catch((error) => {
      return dispatch(textDetectionFail(error));
    });
};

const { releaseDocumentsRequest, releaseDocumentsSuccess, releaseDocumentsFail } = createActions({
  RELEASE_DOCUMENTS_REQUEST: () => { },
  RELEASE_DOCUMENTS_SUCCESS: data => ({ data }),
  RELEASE_DOCUMENTS_FAIL: error => ({ error })
});

export const releaseDocuments = documentIds => (dispatch) => {
  dispatch(releaseDocumentsRequest());

  return Api.releaseDocuments({ documentIds })
    .then(({ data }) => {
      return dispatch(releaseDocumentsSuccess(data));
    })
    .catch((error) => {
      return dispatch(releaseDocumentsFail(error));
    });
};

const { downloadDocumentByCheckedRequest, downloadDocumentByCheckedSuccess, downloadDocumentByCheckedFail } = createActions({
  DOWNLOAD_DOCUMENT_BY_CHECKED_REQUEST: () => { },
  DOWNLOAD_DOCUMENT_BY_CHECKED_SUCCESS: data => ({ data }),
  DOWNLOAD_DOCUMENT_BY_CHECKED_FAIL: error => ({ error })
});

export const downloadDocumentByChecked = body => (dispatch) => {
  dispatch(downloadDocumentByCheckedRequest());

  return new Promise((resolve => {
    Api.downloadDocumentByChecked(body)
      .then(({ data }) => {
        FileSaver.saveAs(data, `Download-${Date.now()}.pdf`);
        dispatch(downloadDocumentByCheckedSuccess(true));
        resolve(data);
      })
      .catch((error) => {
        dispatch(downloadDocumentByCheckedFail(error));
        resolve();
      });
  }));
};

const { editPageInfoRequest, editPageInfoSuccess, editPageInfoFail } = createActions({
  EDIT_PAGE_INFO_REQUEST: () => { },
  EDIT_PAGE_INFO_SUCCESS: data => ({ data }),
  EDIT_PAGE_INFO_FAIL: error => ({ error })
});

export const editPageInfo = (documentId, body) => (dispatch) => {
  dispatch(editPageInfoRequest());

  return Api.editPageInfo(documentId, body)
    .then(({ data }) => {
      return dispatch(editPageInfoSuccess(data));
    })
    .catch((error) => {
      return dispatch(editPageInfoFail(error));
    });
};

const { switchDocumentAndPageRequest, switchDocumentAndPageSuccess, switchDocumentAndPageFail } = createActions({
  SWITCH_DOCUMENT_AND_PAGE_REQUEST: () => { },
  SWITCH_DOCUMENT_AND_PAGE_SUCCESS: data => ({ data }),
  SWITCH_DOCUMENT_AND_PAGE_FAIL: error => ({ error })
});

export const switchDocumentAndPage = (documents, body) => (dispatch) => {
  dispatch(switchDocumentAndPageRequest());

  return Api.switchDocumentAndPage(body)
    .then(() => {
      return dispatch(switchDocumentAndPageSuccess(documents));
    })
    .catch((error) => {
      return dispatch(switchDocumentAndPageFail(error));
    });
};

const { deletePagesRequest, deletePagesSuccess, deletePagesFail } = createActions({
  DELETE_PAGES_REQUEST: () => { },
  DELETE_PAGES_SUCCESS: data => ({ data }),
  DELETE_PAGES_FAIL: error => ({ error })
});

export const deletePages = (body) => (dispatch) => {
  dispatch(deletePagesRequest());

  return Api.deletePages(body)
    .then(() => {
      return dispatch(deletePagesSuccess(true));
    })
    .catch((error) => {
      return dispatch(deletePagesFail(error));
    });
};

const setTransactionStatus = createAction('SET_TRANSACTION_STATUS');

const { fixedChecklistRequest, fixedChecklistSuccess, fixedChecklistFail } = createActions({
  FIXED_CHECKLIST_REQUEST: () => { },
  FIXED_CHECKLIST_SUCCESS: data => ({ data }),
  FIXED_CHECKLIST_FAIL: error => ({ error })
});

export const fixedChecklist = transactionId => (dispatch) => {
  dispatch(fixedChecklistRequest());

  return Api.fixedChecklist(transactionId)
    .then(() => {
      dispatch(setTransactionStatus('FIXED'));
      return dispatch(fixedChecklistSuccess(true));
    })
    .catch((error) => {
      return dispatch(fixedChecklistFail(error));
    });
};


const { updateChecklistRequest, updateChecklistSuccess, updateChecklistFail } = createActions({
  UPDATE_CHECKLIST_REQUEST: () => { },
  UPDATE_CHECKLIST_SUCCESS: data => ({ data }),
  UPDATE_CHECKLIST_FAIL: error => ({ error })
});

export const updateChecklist = (transactionId, documentId, data) => (dispatch, getState) => {
  dispatch(updateChecklistRequest());

  return Api.updateChecklist(transactionId, documentId, data)
    .then(({ data }) => {
      const { propertyDocuments } = getState().document;
      const { login: userInfo } = getState().user;
      const role = get(userInfo, 'result.role');
      if (role !== 'admin' && role !== 'manage') {
        return dispatch(updateChecklistSuccess(data));
      }

      const { documentCategoryId, id } = data;
      const documentsData = propertyDocuments.result;
      const category = find(documentsData.documents, { id: documentCategoryId });
      if (category) {
        let document = find(category.documents, { id });
        if (document) {
          document = { ...document, ...data };
          for (let i = 0; i < category.documents.length; i++) {
            if (category.documents[i].id === id) {
              category.documents[i] = document;
              break;
            }
          }
        }
      }
      let status = '';
      category.documents.forEach(item => {
        if (item.status === 'EROM') {
          status = 'EROM';
          return false;
        }
        return true;
      });
      category.status = status;

      for (let i = 0; i < documentsData.documents.length; i++) {
        if (documentsData.documents[i].id === category.id) {
          documentsData.documents[i] = category;
          break;
        }
      }

      status = '';

      const categories = get(documentsData, 'documents', [])
        .map(item => {
          if (item.status === 'EROM' && status !== 'EROM') {
            status = 'EROM';
          }

          return {
            id: item.id,
            name: item.name
          };
        });
      dispatch(fetchDocumentsOfPropertySuccess(documentsData, categories));
      dispatch(setTransactionStatus(status ? status : 'FIXED'));
      return dispatch(updateChecklistSuccess(data));
    })
    .catch((error) => {
      return dispatch(updateChecklistFail(error));
    });
};

const { alertAcknowledgeRequest, alertAcknowledgeSuccess, alertAcknowledgeFail } = createActions({
  ALERT_ACKNOWLEDGE_REQUEST: () => { },
  ALERT_ACKNOWLEDGE_SUCCESS: data => ({ data }),
  ALERT_ACKNOWLEDGE_FAIL: error => ({ error })
});

export const alertAcknowledge = () => (dispatch) => {
  dispatch(alertAcknowledgeRequest());

  return Api.alertAcknowledge()
    .then(({ data }) => {
      return dispatch(alertAcknowledgeSuccess(data));
    })
    .catch((error) => {
      return dispatch(alertAcknowledgeFail(error));
    });
};
